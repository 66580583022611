<template>
  <div class="financials-header-main">
    <div class="financials-header-main-left">
      <div class="financials-header-title">{{ props.title }}</div>
      <div v-if="isFreeTrial" class="financials-header-trial">{{ t('common.trial') }}</div>
      <div v-if="props.updatedOn" class="financials-header-update-on">{{ updatedOnData }}</div>
      <div v-if="props.traced" class="financials-header-traced-main">
        <div class="financials-header-traced-main-line" />
        <div class="financials-header-traced-main-title">{{ props.traced }}</div>
      </div>
      <CheckPointShowInfo v-if="isShowCheckPoint" :isSelect="props.type !== 'VALUATION'" />
    </div>

    <div class="financials-header-main-right-button">
      <div
        v-if="
          props.showRegenerateButton &&
          !regeneratedRef?.regenerating &&
          props.title !== t('project.nav.generalLedger') &&
          props.title !== t('project.nav.transactions')
        "
        class="financials-header-main-right"
      >
        <el-tooltip
          ref="elvTableTipsRef"
          :show-arrow="false"
          effect="light"
          placement="top"
          popper-class="elv-financials-header-tips"
          :show-after="500"
          :disabled="!isOperationReport"
        >
          <el-button
            class="financials-header-main-right__button"
            :disabled="regeneratedRef?.regenerating"
            :class="{ 'is-operation': isOperationReport }"
            @click="onClickedRegenerate"
          >
            <div v-if="regeneratedRef?.regenerating" class="elv-reports-regenerating">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
              <span>{{ t('report.regenerating') }}</span>
            </div>
            <template v-else>
              <SvgIcon name="reports-restart" width="16" height="16" />
              {{ t('report.regenerateReports') }}
            </template>
          </el-button>
          <template #content>
            <div class="elv-table-tips-content">{{ t('report.regenerateTheReports') }}</div>
          </template>
        </el-tooltip>
      </div>
    </div>
  </div>

  <Regenerated v-if="showRegenerate" ref="regeneratedRef" :type="props.type" />
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import Regenerated from './Regenerated.vue'
import timezone from 'dayjs/plugin/timezone'
import timezoneList from '@/config/timezone'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import CheckPointShowInfo from './CheckPointShowInfo.vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  updatedOn: {
    type: String,
    required: true
  },
  traced: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  showRegenerateButton: {
    type: Boolean,
    default: true
  },
  showBatchEditButton: {
    type: Boolean,
    default: false
  },
  showExportButton: {
    type: Boolean,
    default: false
  },
  showRegenerate: {
    type: Boolean,
    default: false
  }
})

const regeneratedRef = ref()

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()

const trialModuleTypeList = ['reconciliation', 'VALUATION', 'AUXILIARY_CODE']

const projectDetail = computed(() => {
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const updatedOnData = computed(() => {
  const areaTimezone = find(timezoneList, { area: entityStore.entityDetail?.timezone ?? 'UTC' })?.timezone
  return props.updatedOn
    ? `${t('common.updatedOn')} ${dayjs
        .tz(props.updatedOn, entityStore.entityDetail?.timezone ?? 'UTC')
        .format('YYYY/MM/DD HH:mm')} ${areaTimezone}`
    : ''
})

const isOperationReport = computed(() => {
  if (!entityStore.entityDetail?.progress?.postingDoneTime) return false
  return (
    (entityStore.entityDetail?.progress.postingDoneTime && !entityStore.entityDetail?.progress.reportDoneTime) ||
    dayjs(entityStore.entityDetail?.progress.postingDoneTime).isAfter(entityStore.entityDetail?.progress.reportDoneTime)
  )
})

const isShowRegeneratedInfo = computed(() => {
  return regeneratedRef.value?.isShowRegeneratedInfo
})

const isBasicTrial = computed(() => {
  return (
    projectDetail.value?.plan === ProjectPermissionLevel.BASIC && ['reconciliation', 'VALUATION'].includes(props.type)
  )
})

const isFreeTrial = computed(() => {
  if (
    (trialModuleTypeList.includes(props.type) && projectDetail.value?.plan === ProjectPermissionLevel.FREE) ||
    isBasicTrial.value
  ) {
    return true
  }
  return false
})

const isShowCheckPoint = computed(() => {
  const { entityDetail } = entityStore
  const whiteRouteName = [
    'VALUATION',
    'BALANCE_SHEET',
    'CASH_FLOW_STATEMENT',
    'SIGNIFICANT_HOLDINGS',
    'INCOME_STATEMENT',
    'RESTRICTIONS_OF_CRYPTO_ASSETS',
    'CRYPTO_ASSETS_ROLL_FORWARD'
  ]
  return whiteRouteName.includes(props.type) && entityDetail?.enableCheckpoint
})

const onClickedRegenerate = () => {
  regeneratedRef.value?.onClickedRegenerate()
}

defineExpose({ isShowRegeneratedInfo })
</script>
<style lang="scss" scoped>
.elv-report-error-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #1e2024;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }

  img {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 6px;
  }
}

.financials-header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  @media screen and (max-width: 820px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    align-items: baseline;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    align-items: baseline;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  @media screen and (max-width: 390px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    align-items: baseline;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  .financials-header-main-left {
    display: flex;
    margin-left: 20px;
    align-items: center;

    .financials-header-title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 26px;
      line-height: 33px;
      color: $elv-color-000000;
      @media screen and (max-width: 820px) {
        white-space: nowrap;
      }

      @media screen and (max-width: 768px) {
        white-space: nowrap;
      }
      @media screen and (max-width: 390px) {
        white-space: nowrap;
      }
    }

    .financials-header-trial {
      width: 31px;
      height: 18px;
      background-color: #edf0f3;
      border: 0.5px solid #d0d4d9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      margin-left: 13px;
    }

    .financials-header-update-on {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 12px;
      color: $elv-theme-text-color;
      margin-left: 16px;
      padding-top: 7px;
      @media screen and (max-width: 820px) {
        white-space: nowrap;
      }

      @media screen and (max-width: 768px) {
        white-space: nowrap;
      }
      @media screen and (max-width: 390px) {
        white-space: nowrap;
      }
    }

    .financials-header-traced-main {
      display: flex;
      align-items: center;
      margin-top: 5px;

      .financials-header-traced-main-line {
        margin-left: 8px;
        background: $elv-theme-icon-color;
        width: 1px;
        height: 16px;
      }

      .financials-header-traced-main-title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $elv-theme-text-color;
        margin-left: 8px;
        @media screen and (max-width: 820px) {
          white-space: nowrap;
          margin-right: 8px;
        }

        @media screen and (max-width: 768px) {
          white-space: nowrap;
          margin-right: 8px;
        }
        @media screen and (max-width: 390px) {
          white-space: nowrap;
          margin-right: 8px;
        }
      }
    }

    .financials-header-data-source-main {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 6px;
      @media screen and (max-width: 820px) {
        margin-right: 8px;
      }
      @media screen and (max-width: 768px) {
        margin-right: 8px;
      }
      @media screen and (max-width: 390px) {
        margin-right: 8px;
      }

      .financials-header-data-source-main__line {
        margin-left: 8px;
        background: $elv-theme-icon-color;
        width: 1px;
        height: 16px;
      }

      .financials-header-data-source-main__title {
        margin-left: 8px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 12px;
        color: $elv-theme-text-color;
        @media screen and (max-width: 820px) {
          white-space: nowrap;
        }

        @media screen and (max-width: 768px) {
          white-space: nowrap;
        }
        @media screen and (max-width: 390px) {
          white-space: nowrap;
        }
      }

      .financials-header-data-source-main__question {
        margin-left: 4px;
        width: 14px;
        height: 14px;
      }
    }

    :deep(.elv-project-components-check-point-container) {
      padding-top: 7px;
      margin-left: 8px;
    }
  }

  .financials-header-main-right-button {
    display: flex;
    align-items: center;
  }

  .financials-header-main-right {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
  }

  .financials-header-main-right__button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 152px;
    height: 32px;
    border: 1px solid #dde1e6;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;

    svg {
      fill: #1e2024;
      margin-right: 8px;
    }

    &:hover {
      color: #1343bf;
      border: 1px solid #7596eb;

      svg {
        fill: #1343bf;
      }
    }

    &.is-operation {
      color: #1343bf;
      border: 1px solid #7596eb;

      svg {
        fill: #1343bf;
      }
    }

    .elv-reports-regenerating {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        animation: loading-rotate 2s linear infinite;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }
    }
  }
}

@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}

.financials-header-bottom-line {
  margin-top: 8px;
  height: 1px;
  background: $elv-theme-icon-color;
}

:deep(.elv-financials-header-select) {
  margin-left: 4px;
  min-width: 84px;
  max-width: 104px;
  height: 19px;
  line-height: 19px;

  &:hover:not(.el-select--disabled) {
    .el-input__wrapper {
      border-radius: 3px;
      background: #edf0f3;
      box-shadow: none !important;
    }

    .el-input__suffix {
      .el-icon svg {
        fill: #838d95;
      }
    }

    .el-input__inner {
      font-weight: 600;
    }
  }

  .is-focus {
    box-shadow: none !important;

    .el-input__wrapper {
      border-radius: 3px;
      background: #edf0f3;
      box-shadow: none !important;
    }

    .el-input__suffix {
      .el-icon svg {
        fill: #838d95 !important;
      }
    }

    .el-input__inner {
      font-weight: 600;
    }
  }

  .el-input__wrapper {
    height: 19px;
    padding: 2px 4px;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 3px;
  }

  .el-input__inner {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    height: 19px;
  }

  .el-input__suffix-inner {
    width: 14px;
    height: 14px;
  }

  .el-input__suffix {
    width: 14px;
    height: 14px;

    .el-icon {
      margin-left: 4px;
      margin-right: 4px;

      svg {
        fill: #d0d4d9;
      }
    }
  }
}

.elv-confirm-info-header__title {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1e2024;
  margin-bottom: 7px;
}

.elv-financials-header-regenerated {
  width: calc(100% - 40px);
  height: 32px;
  background: #ffffff;
  border: 1px solid #dde1e6;
  border-radius: 2px;
  margin: 12px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 8px;
  position: relative;

  .elv-financials-header-regenerated-error__icon {
    margin-right: 6px;
  }

  .elv-financials-header-regenerated__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    margin-right: 13px;
  }

  :deep(.el-progress) {
    .el-progress-bar {
      width: 132px;
      height: 6px;
    }

    .el-progress__text {
      .elv-financials-header-regenerated-swiper {
        height: 32px;
        overflow: hidden;
        margin-left: 24px;
      }

      .swiper-wrapper {
        height: 32px;

        .swiper-slide {
          height: 32px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #1e2024;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .elv-financials-header-regenerated__close {
    position: absolute;
    right: 10px;
    top: 7px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: #838d95;
  }
}
</style>
<style lang="scss">
.elv-financials-header-select__option {
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
    color: #1e2024;
    width: 140px;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #edf0f3;
  }

  .el-select-dropdown__item.selected {
    font-weight: 600;
  }
}

.elv-financials-header-tips {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  width: 230px;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  line-height: 140%;
  color: #0e0f11;
}
</style>
